import React from 'react';
import { JcCommonProps, JcSize, JcUtils } from '../utils/JcUtils';

export interface JcIconProps extends JcCommonProps {
  readonly size?: JcSize | "unset";
  readonly url: string;
  readonly alt: string;
}

export const JcImage: React.FunctionComponent<JcIconProps> = (props: JcIconProps) => {

  const size = props.size ?? "small";

  const commonProps = JcUtils.commonProps(props, [
    "jc-image",
    "jc-image-size-" + size,
  ]);

  return <img {...commonProps} alt={props.alt} src={props.url} />;
}