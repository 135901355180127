import { Timestamp } from "firebase/firestore";
import { database } from "../../../firebase";
import { ref, push, child, update, onValue, Unsubscribe } from "firebase/database";
import { ChatMessageDTO } from "../dto/ChatMessageDTO";
import { ChatGroupDTO } from "../dto/ChatGroupDTO";
import { JcRPCUpdateObject } from "../../service/JcServiceUtils";

export class ChatService {

    public static sendMessage(chatId: string, userId: string, message: string): Promise<void> {

        const updates: JcRPCUpdateObject = {};
        const newMessageId = push(child(ref(database), "messages/" + chatId)).key;

        if (newMessageId == null) {
            throw Error("Could not create new message.");
        }

        const messageData: ChatMessageDTO = {
            userId: userId,
            message: message,
            timestamp: Timestamp.now().seconds,
            messageId: newMessageId
        };

        updates[`/messages/${chatId}/${newMessageId}`] = messageData;
        updates[`/chats/${chatId}/lastSentMessage`] = messageData;

        return update(ref(database), updates);
    }

    public static subscribeToMessages(chatId: string, onMessages: (messages: ChatMessageDTO[]) => void): Unsubscribe {
        return onValue(ref(database, `messages/${chatId}`), (snapshot) => {
            const data: ChatMessageDTO[] = [];
            snapshot.forEach(child => { data.push(child.val()) })
            onMessages(data);
        });
    }

    public static subscribeToChats(userId: string, onChats: (chats: string[]) => void): Unsubscribe {
        return onValue(ref(database, `users/${userId}/chats`), (snapshot) => {
            const chatIds: string[] = [];
            snapshot.forEach(child => { chatIds.push(child.key) });
            onChats(chatIds);
        });
    }

    public static subscribeToChatGroup(chatId: string, onChatGroup: (groups: ChatGroupDTO) => void): Unsubscribe {
        return onValue(ref(database, `chats/${chatId}`), (snapshot) => {
            onChatGroup(snapshot.val());
        });
    }

    public static addMemberToChat(userId: string, chatId: string): Promise<void> {
        const updates: JcRPCUpdateObject = {};
        updates[`/users/${userId}/chats/${chatId}`] = true;
        updates[`/chats/${chatId}/members/${userId}`] = true;
        return update(ref(database), updates);
    }

    public static createChat(userId: string, userIds: string[], initialMessage: string, groupTitle: string): Promise<void> {
        const updates: JcRPCUpdateObject = {};
        const newChatId = push(child(ref(database), "chats")).key;
        const newMessageId = push(child(ref(database), "messages/" + newChatId)).key;

        if (newChatId == null || newMessageId == null) {
            throw Error("Could not create new chat.");
        }

        const messageData: ChatMessageDTO = {
            userId: userId,
            message: initialMessage,
            timestamp: Timestamp.now().seconds,
            messageId: newMessageId,
        }

        const memberData: { [key: string]: true } = {};
        userIds.forEach(id => {
            memberData[id] = true;
        });

        const chatData: ChatGroupDTO = {
            lastSentMessage: messageData,
            title: groupTitle,
            members: memberData,
            chatId: newChatId,
        }

        updates[`/chats/${newChatId}`] = chatData;
        updates[`/messages/${newChatId}/${newMessageId}`] = messageData;
        [userId, ...userIds].forEach(id => {
            updates[`/users/${id}/chats/${newChatId}`] = true;
        });

        return update(ref(database), updates);
    }
}