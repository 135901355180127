import React from 'react';
import { ThreeDots } from 'react-loader-spinner'
import { JcCommonProps, JcUtils } from '../utils/JcUtils';
import { JcColumn } from './JcBox';


export interface JcLoadingIndicatorProps {

}


export const JcLoadingIndicator: React.FunctionComponent<JcLoadingIndicatorProps> = (props: JcLoadingIndicatorProps) => {

    return <ThreeDots height={80} width={80} color="var(--jc-app-text-color)" />
}


export interface JcFullPageLoadingIndicatorProps extends JcCommonProps {
    label?: React.ReactNode;
}

export const JcFullPageLoadingIndicator: React.FunctionComponent<JcFullPageLoadingIndicatorProps> = (props: JcFullPageLoadingIndicatorProps) => {

    const commonProps = JcUtils.commonProps(props, ["jc-full-page-loading-indicator"]);

    return <div {...commonProps}>
        <JcColumn className="jc-loading-indicator-content">
            <ThreeDots height={80} width={80} color="var(--jc-app-text-color)" />
            {props.label}
        </JcColumn>
    </div>
}