import { HTMLAttributes } from "react";

export interface JcCommonProps extends Pick<HTMLAttributes<HTMLElement>, "className" | "style" | "id"> { }

export type JcSize = "extra-small" | "small" | "medium" | "large" | "extra-large" | "xx-large";

export type JcCommonPropsType = "className";

export type OneOf<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export type JcKeyFunction<T> = (item: T) => string;

export type JcFilterFunction<T> = (item: T, filterString: string) => boolean;

export class JcUtils {


    public static commonProps(props: JcCommonProps, additionalClassNames?: (string | false | null | undefined)[]): JcCommonProps {
        const { className, style, id } = props;
        return {
            className: JcUtils.classNameString((additionalClassNames ?? []).concat(className)),
            style,
            id,
        };
    }

    public static otherProps<T extends JcCommonProps>(props: T): Omit<T, "className" | "style" | "id"> {
        const { className, style, id, ...otherProps } = props;

        return otherProps;
    }

    public static classNameString(classNames: (string | false | null | undefined)[]): string {
        const retNames: string[] = [];
        classNames.forEach(s => {
            if (s) retNames.push(s);
        })
        return retNames.join(" ");
    }
}