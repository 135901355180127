import * as React from "react";
import { JcCommonProps, JcUtils } from "../../utils/JcUtils";
import { JcBox } from "../../JcSDK/JcBox";
import { ChatMessageDTO } from "./dto/ChatMessageDTO";
import { useAppAuth } from "../JcAppAuthWrapper";

export interface ChatMessageProps extends JcCommonProps {
    message: ChatMessageDTO;
    isLastInString: boolean;
}

export const ChatMessage = (props: ChatMessageProps): React.ReactElement<ChatMessageProps> => {
    const { message, isLastInString, ...otherProps } = props;
    const auth = useAppAuth();
    const sentByCurrUser = auth.uid === message.userId;
    const commonProps = JcUtils.commonProps(otherProps, [
        "chat-message",
        isLastInString && "chat-message-last-in-string",
        sentByCurrUser ? "chat-message-right" : "chat-message-left",
    ])

    return <JcBox {...commonProps}>{message.message}</JcBox>;
}